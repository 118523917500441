@import "../../assets/scss/common.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.sidebar-container {
  width: auto;
  min-height: 100vh;
  height: $sidebar-height;
  z-index: $sideBar-z-index;
  position: sticky;
  top: 0;

  @include media-breakpoint-down(sm) {
    position: absolute;
    &.fullScreenSideBar {
      width: $width-100;
    }
  }

  // position: fixed !important;

  background: $side-bar-bg-img;
  background-position: center;
  a {
    @include link-element;
  }

  .transtion {
    @include customTransition(width 1s ease);
  }

  .aggregatedSidebarContainer {
    background: $white;
  }

  .closeAggregatedSideBar {
    right: $sidebar-hamburger-toggle-btn-position-right;
    width: $sidebar-hamburger-toggle-width;
    top: $sidebar-hamburger-toggle-btn-position-top;
    border: none;
    font-size: $sidebar-hamburger-toggle-icon-height;
    background: transparent;
    color: #777;
    z-index: $sidebar-CollapseButton-z-index;
    @include customTransition(all 0.2s ease-in-out);

    svg {
      // height: $sidebar-toggle-icon-height;
      height: $sidebar-toggle-svg-height;
      // @include svg-vertical-align(baseline)
    }

    &:focus {
      outline: none;
    }

    &.rotateButton {
      right: $sidebar-closeButton-toggle-position-right;
      font-size: $sidebar-closeButton-toggle-icon-height;
    }

    &:hover {
      color: $color-sidebar;
    }
  }
}

.sidebar-main {
  @include padding(0);
  z-index: $sidebar-CollapseButton-z-index;
  width: $sidebar-list-width;
  min-width: $sidebar-list-width;
  height: $height-100;
  // background-color: $sidebar-backgroundColor;
  overflow: auto;

  .card {
    background: none;
    color: $black;

    &-header {
      font-size: $mainSidebar-mainHeading-size;
      @include font-w(500);
      @include customTransition(all 0.3s ease);
      background: rgba(255, 255, 255, 0.1);
      font-family: $font-family-abel;
      color: #eee;
      font-size: 1.1rem;
      .link {
        @include link-element;
        color: inherit;
      }

      &:hover {
        color: #fff;
      }
    }

    .side-menu-arrow {
      @include svg-vertical-align(text-bottom);
    }

    &-body {
      //  background: $white;
      color: $color-primary;
      @include padding(0 1.25rem);

      .sub-menu {
        @include padding(0.4rem 0.4rem);
        margin-left: 0.5rem;
        color: $color-grey-dark;
        @include border-bottom(1px, solid, #444);

        &:hover {
          color: $color-primary;
        }
      }
    }

    li {
      a {
        color: #ccc;
        font-size: 0.9rem;
        @include font-w(500);
      }

      &:hover a {
        color: #fff;
      }
    }
  }

  .sidebarLogo {
    // filter: $sidebarLogo-dropShadowValue;
    // -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color= $color-primary)";
    // -webkit-filter: $sidebarLogo-dropShadowValue;
  }
  .sidebar-brandLink {
    color: #fff;
    font-family: $font-family-abel;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: $sidebar-scrollbar-width;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $sidebar-scroll-background;
  }
}

.sidebar-transition {
  -ms-animation: slide-in 0.5s forwards;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.dismiss {
  -ms-animation: slide-out 0.5s forwards;
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

.activeSideBarLink {
  &::after {
    content: "\007C";
    font-size: $sidebar-ActiveLink-arrowIcon-size;
    position: absolute;
    right: $arrowIcon-position-right;
    top: $arrowIcon-position-top;
    color: $color-primary;
  }

  &::before {
    content: "\27A4";
    color: $color-primary;
    font-size: $sidebar-ActiveLink-verticalBarIcon-size;
    position: absolute;
    left: $verticalBarIcon-position-left;
    top: $verticalBarIcon-position-top;
  }
}

@include media-breakpoint-down(sm) {
  .activeSideBarLink {
    &::after {
      right: $arrowIcon-MobileView-position-right;
      top: $arrowIcon-MobileView-position-top;
      font-size: $sidebar-MobileView-ActiveLink-arrowIcon-size;
    }

    &::before {
      left: $verticalBarIcon-MobileView-position-left;
      top: $verticalBarIcon-MobileView-position-top;
      font-size: $sidebar-MobileView-ActiveLink-verticalBarIcon-size;
    }
  }
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  from {
    padding-right: 12rem;
    background: $color-primary;
  }

  to {
    padding-right: 0px;
    background: $color-primary;
  }
}

@-webkit-keyframes slide-out {
  from {
    padding-right: 12rem;
    background: $color-primary;
  }

  to {
    padding-right: 0px;
    background: $color-primary;
  }
}
