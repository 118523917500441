nav>a.nav-item.nav-link,
nav>a.nav-item.nav-link.active {
    border: none;
    text-transform: uppercase;
    @include link-element;

    &:hover {
        color: $color-tabs-border;
    }

    &:focus {
        outline: none;
    }
}

.tab-content {
    border-top: $tab-border-top;
    padding-top: $tab-padding-top;
}

nav>a.nav-item.nav-link {
    color: $black;
}

nav>a.nav-item.nav-link.active {
    position: relative;
    font-weight: 600;

    &::after {
        content: "";
        position: absolute;
        border: $tab-pseudo-border;
        border-radius: $tab-pseudo-border-radius;
        width: $width-100;
        top: $tab-pseudo-border-position-top;
        left: $tab-pseudo-border-position-left;
        background: $color-tabs-border;
    }
}