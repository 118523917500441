.icons {
    @include allicon (0, 0);
    display: inline-block;
}

.delete-icon {
    background-position: -49px -261px;
}

.edit-icon {
    background-position: -10px -261px;
}
.download-icon {
    background-position: -8px -891px;
}
.post-icon {
    background-position: -118px -840px;
}

.view-icon {
    background-position: -65px -840px;
}

.plus-icon {
    background-position: -91px -101px;
    width: 33px;
    height: 33px;
}

.minus-icon {
    background-position: -46px -101px;
    width: 33px;
    height: 33px;
}

.user-icon {
    width: 24px;
    background-position: -7px -96px;
}

.search-icon {
    background-position: -89px -23px;
}
.close-icon{
    background-position: 0 -27px;
    border:none;
    background-color: #000;
    border-radius: 50%;
    width:30px;
    height:30px;
}
.red-cross-icon{
    background-position: -36px -27px;
    border:none;
    border-radius: 50%;
    width:30px;
    height:30px;
}
.check-icon{
    background-position: -88px -65px;
    width: 20px !important;
    height: 20px;
}
.uncheck-icon{
    background-position: -49px -65px;
    width: 20px !important;
    height: 20px;
}
.chevron-small-up-icon{
    background-position: -32px -5px;
    width: 20px !important;
    height: 20px;
}
.chevron-up-icon{
    background-position: -91px -5px;
    width: 20px !important;
    height: 20px;
}
.chevron-small-right-icon{
    background-position: -137px -379px;
    width: 20px !important;
    height: 20px;
}
.chevron-small-left-icon{
    background-position: -115px -379px;
    width: 20px !important;
    height: 20px;
}
.chevron-small-down-icon{
    background-position: -4px -5px;
    width: 20px !important;
    height: 20px;
}
.expand-icon{
    background-position:  -137px -310px;
    width: 20px !important;
    height: 20px;
}
.collapse-icon{
    background-position: -137px -341px;
    width: 20px !important;
    height: 20px;
}
.rct-node-icon{
    display: none;
}
.big-exclamation-icon{
    height: 3.6rem;
    width: 3.6rem;
    background-position: -79px -151px;
}
.icons.refresh-icon{
    background-position: -55px -379px;
    width: 20px !important;
    height: 20px;
    vertical-align: middle;
}
.success-icon{
    background-position: -11px -212px;
    vertical-align: middle;
}
.info-icon{
    background-position: -137px -108px;
    width: 20px !important;
    height: 20px;
}
.menupull-icon{
    width: 15px;
    height: 15px;
    display:inline-block;
    border-radius: 50%;
    margin-right:3px;
    vertical-align: middle;
    &.success-icon{
        background:rgb(34, 190, 6);
    }
    &.in-progress-icon{
        background:rgb(255, 217, 4);
    }
    &.partial-success-icon{
        background: linear-gradient(90deg, rgba(0,255,29,1) 51%, rgba(254,0,0,1) 51%);
    }
    &.failed-icon{
        background:rgb(197, 6, 6);
    }
    &.scheduled-icon{
        @include allicon (0, 0);
        width: 15px !important;
        height: 15px !important;
        background-position: -90px -225px;
    }
}