
$datePicker-popper-z-index:200;

//NAVBAR
$navbar-master-z-index: 200;

$header-navbar-z-index: $navbar-master-z-index;
$header-user-icon-z-index: $navbar-master-z-index;

//CUSTOM DROP DOWN SELECT BOXES

$dropdown-z-index:200;

//SIDEBAR
$sidebar-master-z-index: 210;

//REACT DATE PICKER

$datePicker-popper-z-index:215;

$sideBar-z-index:$sidebar-master-z-index;

// sidebar-Expand Collapse-button
$sidebar-CollapseButton-z-index: $sidebar-master-z-index;


// TOASTER

$toaster-z-index: 220;

// LOADER

$loader-z-index: 230;