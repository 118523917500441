// @import "../../assets/scss/common.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.form-control {
  font-size: inherit;

  &-withError {
    border-bottom: 2px solid $color-primary !important;
    // box-shadow: $form-control-error-shadow;
    //  -webkit-box-shadow: $form-control-error-shadow;

    &:focus {
      border-color: $color-primary;
      box-shadow: none;
    }
  }

  &.hiddenInput {
    border: none !important;
    pointer-events: none;
    background-color: transparent !important;
  }

  &.fullyBordered {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;

    & + label {
      position: absolute;
      left: 15px;
      top: 12px;
      transition: all 0.3s;
      margin-left: 5px;
      background-color: $white;
    }

    &:focus,
    &:not(:placeholder-shown) {
      box-shadow: none;

      & + label {
        font-size: 0.8rem !important;
        top: -4px !important;
      }
    }
  }
}

@include removeDefaultNumberSpinners;

.label-bold {
  font-weight: $label-font-weight;
}

.legend-normal {
  font-weight: 500;
  pointer-events: none;
  font-size: 1.05rem;
  width: auto;
  display: inline-block;
}

.fieldset {
  border: 1px solid #ced4da;
  margin: 0 0 20px;
  padding: 15px;

  &.space-small{
    padding: 0 15px;
  }
}

.error-message {
  color: $color-primary;
  @include font-w(500);
  font-size: $font-size-sm;
}

.checkbox-error-message {
  color: $color-primary;
  @include font-w(500);
}

.cursor-pointer {
  cursor: pointer !important;
}

.subLabel {
  font-size: $font-size-subLabel;
}

.react-datepicker__close-icon {
  @include media-breakpoint-down(xs) {
    padding: 0px;
  }

  &:after {
    background-color: transparent;
    color: inherit;
    font-weight: 700;
    @include custom-font-size(0.5);

    @include media-breakpoint-down(xs) {
      margin: 0px;
      padding: 0px;
      @include custom-font-size(0.2);
    }
  }
}

.react-datepicker__input-container {
  .form-control {
    @include media-breakpoint-down(xs) {
      padding: 0.25rem 0.25rem;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $disabled-background-gray;
  opacity: 1;
  border: none !important;
  padding-left: 5px !important;
}

.btn {
  font-size: $button-font-size;
}

button[type="submit"] {
  &.btn {
    color: #fff;
    // background-color: $submit-button-background;
    // border: 2px solid $submit-button-background;
    // box-shadow: $button-boxShadow;
    background-color: $color-primary;
    border: 2px solid $color-primary;
    text-transform: uppercase;
    padding: $button-padding-class;

    &.medium-btn {
      padding: $button-padding-class-medium;
    }

    &:hover {
      // box-shadow: $button-boxShadow-active;
    }

    &:active {
      // box-shadow: $button-boxShadow-active;
    }

    &:focus {
      outline: none;
    }
  }
}

.btn.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;

  &:active {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.btn.btn-secondary {
  background: none !important;
  border: 2px solid $color-primary;
  color: $color-primary !important;
  text-transform: uppercase;
  padding: $button-padding-class;

  &.medium-btn {
    padding: $button-padding-class-medium;
  }

  &:active {
    color: $color-primary;
  }
}

.datePicker-btn.btn.btn-secondary {
  padding: 0 17px;
}

.datePicker-btn.btn.btn-secondary {
  padding: 0 17px;
}

.mat-input {
  &:not(.mat-checkbox):not(.mat-radio) {
    margin: 5px 0 15px;

    input[type="text"],
    input[type="number"],
    input[type="email"],
    select,
    textarea {
      @include mat-field-border();
      padding-left: 0;

      &:focus {
        border-bottom: $material-highlight-border;
      }

      &:focus,
      &:not(:placeholder-shown) {
        box-shadow: none;

        & + label {
          font-size: 0.8rem;
          top: -10px;
        }
      }
    }

    label {
      @include mat-label();
    }

    .mat-selected {
      font-size: $material-label-top-font;
      top: -10px;
    }

    .select-mat-lebel {
      pointer-events: none;
    }
  }
}

.mat-small-label {
  .mat-input {
    .mat-label {
      font-size: $material-label-top-font;
    }
  }
}

.mat-input.mat-checkbox {
  label {
    margin-left: 15px;
  }
}

.timepicker-label {
  @include mat-label();
  left: 25px;
  top: 9px;
  pointer-events: none;
  font-size: 0.8rem;
  top: -10px;
  background: #fff;
  padding: 0 3px;
  z-index: 1;
}

.custom-time-picker:focus ~ .timepicker-label {
  font-size: $material-label-top-font;
  top: -10px;
}

.custom-select-label {
  font-size: $material-label-top-font;
  margin-bottom: 0;
}

.add-hrs {
  &.btn-link {
    color: #fff;

    &:focus {
      box-shadow: none;
    }

    svg {
      background: #000;
      border-radius: 50%;
    }
  }
}

.custom-checkbox {
  .custom-control-label {
    font-weight: bold;

    &::before {
      border-color: #000 !important;
      background: none !important;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path stroke='%23fff' fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>");
    transform: scale(2);
    left: -21px;
    top: 3px;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    opacity: 0.6;
  }
}

.custom-radio {
  .custom-control-label {
    font-weight: bold;

    &::before {
      border-color: #000 !important;
      background: none !important;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #000;
    border-radius: 50%;
  }

  .custom-control-input:disabled ~ .custom-control-label {
    opacity: 0.6;
  }
}

.mat-date-time-picker {
  margin-top: 23px;

  input {
    border: none;
    border-radius: 0;
    border-bottom: $material-border;

    &:focus {
      border-bottom: $material-highlight-border;
    }
  }
}

.side-menu-arrow {
  color: #ccc;
}

.operating-hrs-heading {
  font-size: 0.7rem;
  color: #666;
}

.batch-operating-hrs-table {
  .rdt_TableCell {
    font-size: 1rem;

    .operation-list {
      font-size: 0.8rem;
    }

    .expand-accordion {
      font-size: 0.7rem;
      font-weight: bold;
    }
  }
}

.round-button-base {
  @include round-arrow-base();
}

.switch-checkbox {
  display: none;

  & ~ label.switch-label {
    position: relative;
    cursor: pointer;
    width: 60px;
    height: 30px;
    background: $color-grey-light-3;
    border-radius: 25px;
    margin-top: 8px;
    transition: all 0.5s;
    &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      left: 5px;
      top: 5px;
      transition: all 0.3s;
    }
  }

  &:checked {
    & ~ label.switch-label {
      background: $switch-enabled-color;

      &:after {
        content: "";
        background-color: #fff;
        left: 34px;
      }
    }
  }
}

.steps-item {
  &:last-child {
    .steps-wrapper {
      margin-right: 0;

      .step-number {
        &:after {
          display: none;
        }
      }
    }
  }

  .steps-wrapper {
    display: inline-block;
    text-align: center;
    margin-right: 30px;

    @include media-breakpoint-up(lg) {
      margin-right: 60px;
    }

    .steps-name {
      font-weight: bold;
      font-size: 1.3rem;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .step-number {
      width: 55px;
      height: 55px;
      display: block;
      margin: auto;
      align-items: center;
      box-sizing: border-box;
      padding-top: 11px;
      background: #999;
      color: #fff;
      border-radius: 50%;
      border: 6px solid #ccc;
      box-shadow: 0 0 0px 1px #999;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 88px;
        position: absolute;
        top: 21px;
        height: 4px;
        background: #ccc;
        left: 100%;
      }
    }
  }

  &.active,
  &.completed {
    .step-number {
      background: #000;
    }
  }
}

.count-status-wrapper {
  position: static;

  @include media-breakpoint-up(lg) {
    position: relative;
    z-index: 9;
    top: 1px;
    margin-left: 10px;
  }

  .count-status {
    padding: 5px 10px;
    display: block;

    @include media-breakpoint-up(lg) {
      display: inline;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &.initiated {
      background: #666;
      color: #fff;
    }

    &.failed {
      background: $color-primary;
      color: #fff;
    }

    &.completed {
      background: $color-green;
      color: #fff;
    }
  }
}

.steps-outer {
  .steps-navbar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -100px;
    width: 300px;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }
}

.cross {
  position: absolute;
  right: -8px;
  top: -10px;
}

.custom-spinner-grow {
  position: relative;

  &:after {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 7px solid $color-primary;
    border-bottom: 7px solid #fff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    left: 45%;
    top: 45%;
    transform: translateX(-50%);
  }

  img {
    filter: blur(5px);
  }
}
.steps-builder {
  .btn-primary {
    &:disabled {
      color: #000;
      font-weight: 600;
    }
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.table-head-class {
  &.closed {
    text-align: center;
  }
}

.drag {
  transition: all 0.4s;
}

.dragged {
  background-color: rgba(66, 177, 15, 0.384);
  opacity: 0.5;
  cursor: grabbing;
}
.whole-day {
  .custom-control-label {
    text-indent: -10000px;

    @include media-breakpoint-up(lg) {
      text-indent: unset;
    }
  }
}

.info-tooltip-text{
  background-color: #000;
  color:#fff;
  border-radius: 6px;
  padding:5px;
  font-size: 0.8em;
  display:none;
  position: absolute;
  z-index: 9;
  bottom:30px;
  width: 300px;
}
.info-tooltip-text:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
  position: absolute;
  left: 7px;
  bottom: -6px;
  }

.info-tooltip-holder{
  position: relative;
  cursor: pointer;
  &:hover{
  .info-tooltip-text{
    display:block;
  }
}
}
.switch-checkbox.pointer-events-none + label{
 pointer-events: none;
}
// .react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name{
//   line-height: 1.2rem !important;
// }
