%display-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

%flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

%flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

%flex-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}


//BOX SHADOW

%remove-boxShadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}